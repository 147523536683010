import {StructureDescription, ComponentColumn, ComponentDefinition, ComponentLine} from "../interfaces";
import {v4 as uuidv4} from 'uuid'

class Component implements ComponentDefinition {
    name: string = '';
    show: boolean = true;
    htmlTag: string = '';
    componentattributes: {} = {};
    events: {
        type: string,
        callBack: Function
    }[] = []
    componentchildren: {
        component: ComponentDefinition,
        html: string
    }[] = []

    groups: string[] = []
    componentstyle = ''
    lines: ComponentLine[] = []
   
    constructor(name: string, htmlTag: string, attributes?: {}) {
        this.name = name
        this.htmlTag = htmlTag
        if (attributes) {
            this.componentattributes = attributes
        }
    }

    setStyle(style: string) {
        this.componentstyle = style
    }

    setGroups(newGroups: string[]) {
        this.groups = newGroups
    }

    addAttributes(attr: {}) {
        let keys = Object.keys(attr)
        for (let key of keys) {
            this.componentattributes[key] = attr[key]
        }
    }

    setAttribute(name: string, value: string) {
        let element = window['components'][this.name] as HTMLElement
        if (element) {
            element.setAttribute(name, value)
        } else {
            console.warn('Element not found', this.name)
        }
    }

    addEvent(type: string, callBack: Function) {
        this.events.push({type, callBack})
    }

    addChild(component: ComponentDefinition, html: string) {
        this.componentchildren.push({component, html})
    }

    addLine(line: ComponentLine) {
        this.lines.push(line)
    }

    setLines(lines: ComponentLine[]) {
        this.lines = lines
    }

    getLine(name: string) {
        return this.lines.find(l => l.name === name)
    }

    getLineAt(index: number) {
        return this.lines[index]
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Get the HTML component of the component.
    // The HTML component is the object to interact with at runtime to modify specific parameters (the export attributes)
    // and invoke the web component's behaviour.
    getHTMLComponent() {
        return window['components'][this.name]

    }
}

class Structure implements StructureDescription {
    lines: ComponentLine[] = [] // the "screens"
    guid: string = ''
    outlineStructure: boolean = false
    name: string = ''

    constructor(name: string) {
        this.guid = uuidv4()
        this.name = name
    }

    addLine = (line: ComponentLine) => {
        this.lines.push(line)
    }
    getLine = (name: string) => {
        return this.lines.find(l => l.name === name)
    }
    removeLine = (name: string) => {
        this.lines = this.lines.filter(l => l.name !== name)
    }

    // set one single component for the structure by creating one line and one column, in which the component is set.
    setComponent = (component: ComponentDefinition) => {
        let line = new Line(this.guid + '_line', true, true)
        let column = new Column(this.guid + '_column', true, 12)
        column.setComponent(component)
        line.addColumn(column)
        this.addLine(line)
    }

    // overwrites all prvious definitions!
    // therefore: set all screens at once
    setScreens = (screens: Screen[]) => {
        this.lines = screens
    }

    setLines = (lines: ComponentLine[]) => {
        this.lines = lines
    }

    showStructure = (show: boolean) => {
        this.outlineStructure = show
    }
}

class Line implements ComponentLine {
    show: boolean = true
    name: string = ''
    columns: ComponentColumn[] = []
    flexGrow: boolean = true
    groups: string[] = []
    componentstyle: string = ''

    constructor(name: string, show: boolean, flexGrow: boolean) {
        this.name = name
        this.show = show
        this.flexGrow = flexGrow
    }

    addColumn(column: ComponentColumn) {
        this.columns.push(column)
    }

    getColumn(name: string) {
        return this.columns.find(col => col.name === name)
    }

    getColumnAt(index: number) {
        return this.columns[index]
    }

    removeColumn(name: string) {
        this.columns = this.columns.filter(col => col.name !== name)
    }

    setGroups(newGroups: string[]) {
        this.groups = newGroups
    }

    setComponent(component: ComponentDefinition) {
        if(this.columns.length === 0) {
            let column = new Column(this.name + '_column', true, 12)
            column.setComponent(component)
            this.addColumn(column)
        }else{
            this.columns[0].setComponent(component)
        }
    }

    setStyle(style: string) {
        this.componentstyle = style
    }
}

export interface Screen extends Line {
    addLine: (line: Line) => void
    addComponent: (comp: Component) => void
}

class Column implements ComponentColumn {
    name: string = ''
    show: boolean = true
    width: number = 1
    component: ComponentDefinition = null
    groups: string[] = []
    lines: Line[] = []
    componentstyle: string = ''

    constructor(name: string, show: boolean, width: number) {
        this.name = name
        this.show = show
        this.width = width
    }

    setComponent(comp: ComponentDefinition) {
        this.component = comp
    }

    getComponent() {
        return this.component
    }

    setGroups(newGroups: string[]) {
        this.groups = newGroups
    }

    addLine(line: Line) {
        this.lines.push(line)
    }

    setLines(lines: Line[]) {
        this.lines = lines
    }

    setStyle(style: string) {
        this.componentstyle = style
    }
}

function Button(name: string, attributes: {}) {
    let button = new Component(name, 'bc-button')
    button.addAttributes(attributes)
    return button
}

function Table(name: string, attributes: {}) {
    let table = new Component(name, 'object-table')
    table.addAttributes(attributes)
    return table
}

function Tabs(data: { mainName: string, num: number, texts: string[], tabIds: string[], tabGroupId: string, currentTab: string }) {
    const {mainName, num, texts, tabIds, tabGroupId, currentTab} = data

    // Tab Component
    let tabComponent = new Component(mainName, 'bc-tabs')
    tabComponent.addAttributes({
        tabgroupid: tabGroupId,
        currenttab: currentTab,
    })

    // Create Container for Tab Links
    let tabLinkContainer = new Component('tab1LinkContainer_' + mainName, 'div')
    tabLinkContainer.addAttributes({slot: 'tablinks'})
    tabLinkContainer.setStyle('display:flex;  align-items: center; width: 100%;')

    // Create Container for Tab Panels
    let tabPanelContainer = new Component('panelContainer_' + mainName, 'div')
    tabPanelContainer.setStyle('height: 100%; width: 100%;')
    tabPanelContainer.addAttributes({slot: 'tabpanels'})
    const links: Component[] = []
    const panels: Component[] = []
    const names : string[] = []

    for (let i = 0; i < num; i++) {
        let tabLink = new Component('tabLink1_' + mainName, 'tab-link')
        tabLink.addAttributes({
            text: texts[i],
            tabid: tabIds[i],
            tabgroupid: tabGroupId,
        })
        tabLinkContainer.addChild(tabLink, null)
        links.push(tabLink)

        // Create TabPanel1 / TabPanel2
        let tabPanel = new Component('tabPanel_' + mainName, 'tab-panel')
        tabPanel.addAttributes({
            tabid: tabIds[i],
            tabgroupid: tabGroupId,
        })
        names.push(texts[i])
        tabPanelContainer.addChild(tabPanel, null)
        panels.push(tabPanel)
    }

    tabComponent.addChild(tabLinkContainer, null)
    tabComponent.addChild(tabPanelContainer, null)

    const getTabPanel = (name: string) => {
        for(let i = 0; i<names.length; i++) {
            if(names[i] === name) {
                return panels[i]
            }
        }
    }

    return {
        tabComponent,
        links,
        panels,
        getTabPanel
    }
}

function Screens(names: string[]) {

    let lines: Screen[] = []
    let components: Component[] = []
    let cachedNames = names
    for (let i = 0; i < names.length; i++) {
        let line = new Line(names[i], i === 0 ? true : false, true)

        let columnName = 'column_' + names[i]
        let column = new Column(columnName, true, 12)
        line.addColumn(column)
        let component = new Component('div_' + columnName, 'div')
        component.setStyle('width: 100%; height: 100%;')
        components.push(component)
        column.setComponent(component)
        // @ts-ignore
        line.addLine = (line: Line) => {
            component.addLine(line)
        }
        // @ts-ignore
        line.addComponent = (comp: Component) => {
            component.addChild(comp, null)
        }
        // @ts-ignore
        lines.push(line)
    }

    const setScreen = (name: string) => {
        for (let i = 0; i < lines.length; i++) {
            let searchedName = cachedNames[i]
            let element = window['components'][searchedName] as HTMLElement
            if (element) {
                if (lines[i].name === name) {
                    element.classList.remove('hidden')
                } else {
                    element.classList.add('hidden')
                }
            }
        }
    }

    const getScreen = (name: string) => {
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].name === name) {
                return lines[i]
            }
        }
    }

    return {
        screens: lines,
        setScreen,
        getScreen
    }
}

function EntityManager(data: {
    name: string,
    dataviewguid: string,
    entityguid: string,
    hasMap: boolean,
    hasDocuments: boolean,
    hasActions: boolean,
    hasRelationships: boolean,
    hasMeasurements: boolean,
    entityAttributes?: {}
})
{

    const {name, dataviewguid, entityguid, hasMap, hasActions, hasRelationships, hasMeasurements, hasDocuments, entityAttributes} = data

    let entityManager = new Component(name, 'entity-manager')
    entityManager.addAttributes({
        dataviewguid: dataviewguid,
        entityguid: entityguid,
        dispatchnavigationevents: 'false',
        ...entityAttributes
    })
    let slots: Component[] = []


    let objectRenderer = new Component('object-renderer_' + name, 'simple-object-renderer')
    objectRenderer.addAttributes({
        slot: 'entity-content',
        dataviewguid: dataviewguid,
        objectid: entityguid,
        open: 'YES',
        objecttype: "Entity",
        showbuttons: "false",
    })
    entityManager.addChild(objectRenderer, null)
    slots.push(objectRenderer)

    if (hasMap) {
        let entityMap = new Component('entity-map_' + name, 'entity-map')
        entityMap.addAttributes({
            slot: 'map-content',
            dataviewguid: dataviewguid,
            entityguid: entityguid
        })
        entityManager.addChild(entityMap, null)
        slots.push(entityMap)
    }

    if (hasDocuments) {
        let entityDocuments = new Component('entity-documents_' + name, 'entity-documents')
        entityDocuments.addAttributes({
            slot: 'documents-content',
            dataviewguid: dataviewguid,
            entityguid: entityguid
        })
        entityManager.addChild(entityDocuments, null)
        slots.push(entityDocuments)
    }


    if (hasActions) {
        let entityActions = new Component('entity-actions_' + name, 'entity-actions')
        entityActions.addAttributes({
            slot: 'actions-content',
            dataviewguid: dataviewguid,
            entityguid: entityguid
        })
        entityManager.addChild(entityActions, null)
        slots.push(entityActions)
    }

    if (hasRelationships) {
        let entityRelations = new Component('entity-relations_' + name, 'entity-relations')
        entityRelations.setStyle('width: 100%;')
        entityRelations.addAttributes({
            slot: 'relations-content',
            dataviewguid: dataviewguid,
            entityguid: entityguid
        })
        entityManager.addChild(entityRelations, null)
        slots.push(entityRelations)
    }

    if (hasMeasurements) {
        let entityMeasurments = new Component('entity-measurements_' + name, 'entity-measurements')
        entityMeasurments.setStyle('width: 100%;')
        entityMeasurments.addAttributes({
            slot: 'measurements-content',
            dataviewguid: dataviewguid,
            entityguid: entityguid
        })
        entityManager.addChild(entityMeasurments, null)
        slots.push(entityMeasurments)
    }

    const setEntity = (dataviewguid: string, entityguid: string) => {
        console.log('setEntity', dataviewguid, entityguid)
        entityManager.setAttribute('dataviewguid', dataviewguid)
        entityManager.setAttribute('entityguid', entityguid)

        slots[0].setAttribute('dataviewguid', dataviewguid)
        slots[0].setAttribute('objectid', entityguid)

        for (let i = 1; i < slots.length; i++) {
            slots[i].setAttribute('dataviewguid', dataviewguid)
            slots[i].setAttribute('entityguid', entityguid)
        }
    }


    return {
        entityManager,
        slots,
        setEntity,
    }
}

function Popover(data: {
    name: string,
    attributes: {},
    buttonAttributes: {},
    options: {
        icon?: string,
        text: string,
        event: Function
    }[]
})
{
    const {name, attributes, buttonAttributes, options} = data
    let popover = new Component(name, 'custom-pop-over')
    popover.addAttributes({
        ...attributes
    })
    let button = new Component(name + "_button", 'bc-button')
    button.addAttributes(buttonAttributes)
    button.addAttributes({slot: 'open-close', dropdown: 'true'})
    let div = new Component(name + '_content_div', 'div')
    div.addAttributes({
        slot: 'content'
    })

    for (let i = 0; i < options.length; i++) {
        let optionTag = new Component(name + '_option_' + i, 'popover-option')
        if (options[i].icon) {
            optionTag.addAttributes({
                icon: options[i].icon
            })
        }

        optionTag.addAttributes({
            text: options[i].text,
        })

        optionTag.addEvent('click', options[i].event)
        div.addChild(optionTag, null)
    }

    popover.addChild(button, null)
    popover.addChild(div, null)
    return popover
}

function LinesStructure(data: {
    containerName: string,
    linesDefinition: {
        name: string,
        flexGrow: boolean,
        columns: number[]
    }[]
}) {

    const {containerName, linesDefinition} = data

    let container = new Component(containerName, 'div')
    container.setStyle('width: 100;height: 100%;')

    let lines: Line[] = []
    for (let i = 0; i < linesDefinition.length; i++) {

        let def = linesDefinition[i]
        let line = new Line(def.name, true, def.flexGrow)
        for (let j = 0; j < def.columns.length; j++) {
            let column = new Column(def.name + '_col_' + (j + 1), true, def.columns[j])
            line.addColumn(column)
        }
        lines.push(line)
    }
    container.setLines(lines)

    const getLine = (name: string) => {

        for (let i = 0; i < lines.length; i++) {
            if (lines[i].name === name) {
                return lines[i]
            }
        }
        return null
    }

    return {
        container,
        lines,
        getLine
    }
}

export const componentConstructorHelper = {
    Structure,
    Line,
    Column,
    Component,
    Button,
    Table,
    Tabs,
    EntityManager,
    Screens,
    Popover,
    LinesStructure
}

