// import bc from 'bc'
// import { writable } from 'svelte/store';
import CcUgdmMetaEnvironment from 'CcUgdmMetaEnvironment'
import type {EntityType} from './metadataManager'
// import  {metadataManager} from './metadataManager'
import rfdc from 'rfdc'
import deepEqual from 'deep-equal'
import {constants} from "../constants";

let clone = rfdc()

export interface ParameterMetaData {
    type: string
    domaine: string
    data: any
}
// export const constants = constants;

export const PARAMS = {
    constants: constants,
    application: {
        authenticationRequired: 'authenticationRequired',
        applicationGUID: 'applicationGUID',
        applicationName: 'applicationName',
        domainNamePrefix: 'domainName_',
        applicationDescription: 'applicationDescription',
        domainGuid: 'domainGuid',
        languageGuid: 'languageGuid',
        languageName: 'languageName',
        languageLongName: 'languageLongName',
        clientCulture: 'clientCulture',
        languageExplicit: 'languageExplicit',
        implementationGUID: 'implementationGUID',
        classificationTreeRootGUID: 'classificationTreeRootGUID',
        measurementProgrammeTreeRootGuid: 'measurementProgrammeTreeRootGuid',
        UITheme: 'UITheme',
    },
    request: {
        CcUgdmMetaEnvironment: 'CcUgdmMetaEnvironment',
        CcUgdmQueryParameter: 'CcUgdmQueryParameter',
        message: 'message',
        error: 'error',
        returnUrl: 'returnUrl',
        pageGUID: 'pageGUID',
        pageName: 'pageName',
        pageTitle: 'pageTitle',
    },
    user: {
        userName: 'userName',
        userId: 'userId',
        roles: 'roles',
        roleIds: 'roleIds',
        bearerToken: 'bearerToken',
    },
    urls: {
        applicationBaseUrl: 'applicationBaseUrl',
        APIBaseUrl: 'APIBaseUrl',
        OdataBaseUrl: 'OdataBaseUrl',
        ApplicationDataServiceBaseUrl: 'ApplicationDataServiceBaseUrl',
    },
    kendo: {
        document: 'document',
    },
    metadata: 'metadata',
}

export type ParameterGroups =
    | 'globalSettings'
    | 'request'
    | 'user'
    | 'baseURLs'
    | 'metadata'
    | 'EDIT_DROPDOWN'
    | 'UPDATED_KANBANITEM'
    | 'NEW_KANBAN_ITEM'
    | 'SHOW_KANBAN_ITEM'

// export const PARAM_GROUPS = {
//     globalSettings: 'globalSettings',
//     request: 'request',
//     user: 'user',
//     baseURLs: 'baseURLs',
//     metadata: 'metadata',
//     EDIT_DROPDOWN: 'EDIT_DROPDOWN',
//     UPDATED_KANBANITEM: 'UPDATED_KANBANITEM',
//     NEW_KANBAN_ITEM: 'NEW_KANBAN_ITEM',
//     SHOW_KANBAN_ITEM: 'SHOW_KANBAN_ITEM',
// }

// export const PARAM_GLOBAL_SETTINGS = {
//     authenticationRequired: 'authenticationRequired',
//     applicationGUID: 'applicationGUID',
//     applicationName: 'applicationName',
//     applicationDescription: 'applicationDescription',
//     domainGuid: 'domainGuid',
//     languageGuid: 'languageGuid',
//     languageName: 'languageName',
//     languageLongName: 'languageLongName',
//     clientCulture: 'clientCulture',
//     languageExplicit: 'languageExplicit',
//     implementationGUID: 'implementationGUID',
//     classificationTreeRootGUID: 'classificationTreeRootGUID',
//     measurementProgrammeTreeRootGuid: 'measurementProgrammeTreeRootGuid',
//     UITheme: 'UITheme',
// }

// export const PARAM_URLS = {
//     applicationBaseUrl: 'applicationBaseUrl',
//     APIBaseUrl: 'APIBaseUrl',
//     OdataBaseUrl: 'OdataBaseUrl',
//     ApplicationDataServiceBaseUrl: 'ApplicationDataServiceBaseUrl'
// }

/* now in constants
export interface ParameterElement {
    parameter: string
    callbacks: {
        id: string
        fn: Function
    }[]
}
*/

export interface CcUgdmMetaEnvironmentInterface {
    Message: string
    Error: string
    ReturnUrl: string
    UserName: string
    UserId: null
    Roles: []
    RoleIds: []
    OwnsType: null | string
    CanReadType: null | string
    CanCreateType: null | string
    CanModifyType: null | string
    CanDeleteType: null | string
    ApplicationBaseUrl: string
    ApiBaseUrl: string
    OdataBaseUrl: string
    ApplicationDataServiceBaseUrl: string
    AuthenticationServerAddress: string
    AuthenticationRequired: boolean
    BearerToken: string | null
    IsConfiguration: boolean
    ProductionApplicationBaseUrl: null | string
    LanguageGuid: string
    LanguageName: null | string
    LanguageLongName: null | string
    LanguageExplicit: false
    ClientCulture: string
    ApplicationGuid: string
    ApplicationName: string
    ApplicationLongName: string
    ApplicationDescription: string
    ImplementationGuid: null | string
    ClassificationTreeRootGuid: string
    MeasurementProgrammeTreeRootGuid: string
    PageGuid: null | string
    PageName: null | string
    PageTitle: null | string
    ResourceDefinitionId: null | string
    DomainGuid: null | string
    ParentDomainGuid: null | string
    SelectedTypeGuid: null | string
    ParentTypeGuid: null | string
    SelectedEntityTypeGuid: null | string
    SelectedEntityGuid: null | string
    ApplicationSpecific: false,
    ApplicationSchemaName: string,
    CoreSchemaName: string,
    GeodataSchemaName: string,
    MetaSchemaName: string,
    ServerVersion: string,
    ClientVersion: string,
}

// environment parameters passed in the web page
export interface ApplicationParameterInterface {

    [key: string]: any // the array for runtime configuration parameters

    serverVersion: string
    clientVersion: string

    authenticationRequired: boolean
    applicationGUID: string
    applicationName: string
    applicationDescription: string
    domainGuid: string
    languageGuid: string
    languageName: string
    languageLongName: string
    clientCulture: string
    languageExplicit: false
    implementationGUID: string
    classificationTreeRootGUID: string
    measurementProgrammeTreeRootGuid: string
    UITheme: string

    CcUgdmMetaEnvironment: object
    CcUgdmQueryParameter: object
    message: string
    error: string
    returnUrl: string
    pageGUID: string
    pageName: string
    pageTitle: string

    userName: string
    userId: string
    roles: string[]
    roleIds: string[]
    bearerToken: string

    applicationBaseUrl: string
    APIBaseUrl: string
    OdataBaseUrl: string // Url for Ugdm Types
    ApplicationDataServiceBaseUrl: string // To obtain metadata
    // authenticationServerAdresse: null //Not used anymore

    document: Document
    metadata: ParameterMetaData[]
}

const createDefaultConfig = (config: CcUgdmMetaEnvironmentInterface): ApplicationParameterInterface => {
    if (config) {
        return {
            // Application
            authenticationRequired: config.AuthenticationRequired,
            applicationGUID: config.ApplicationGuid,
            applicationName: config.ApplicationName,
            applicationDescription: config.ApplicationDescription,
            domainGuid: config.DomainGuid,
            languageGuid: config.LanguageGuid,
            languageName: config.LanguageName,
            languageLongName: config.LanguageLongName,
            clientCulture: config.ClientCulture,
            languageExplicit: config.LanguageExplicit,
            implementationGUID: config.ImplementationGuid,
            classificationTreeRootGUID: config.ClassificationTreeRootGuid,
            measurementProgrammeTreeRootGuid: config.MeasurementProgrammeTreeRootGuid,
            UITheme: 'primary',
            // Page
            CcUgdmMetaEnvironment: {},
            CcUgdmQueryParameter: {},
            message: config.Message,
            error: config.Message,
            returnUrl: config.ReturnUrl,
            pageGUID: config.PageGuid,
            pageName: config.PageName,
            pageTitle: config.PageTitle,
            // USER
            userName: config.UserName,
            userId: config.UserId,
            roles: config.Roles,
            roleIds: config.RoleIds,
            bearerToken: config.BearerToken ? config.BearerToken : '',
            // URLs
            applicationBaseUrl: config.ApplicationBaseUrl,
            APIBaseUrl: config.ApiBaseUrl,
            OdataBaseUrl: config.OdataBaseUrl,
            ApplicationDataServiceBaseUrl: config.ApplicationDataServiceBaseUrl, // To obtain metadata
            // authenticationServerAdresse: null //Not used anymore
            document: window.document,
            applicationSchemaName: config.ApplicationSchemaName,
            coreSchemaName: config.CoreSchemaName,
            geodataSchemaName: config.GeodataSchemaName,
            metaSchemaName: config.MetaSchemaName,
            serverVersion: config.ServerVersion,
            clientVersion: config.ClientVersion,

            metadata: [],
        }
    } else {
        return {
            authenticationRequired: false,
            applicationGUID: '',
            applicationName: '',
            applicationDescription: '',
            domainGuid: '',
            languageGuid: '',
            languageName: 'de',
            languageLongName: '',
            clientCulture: '',
            languageExplicit: false,
            implementationGUID: '',
            classificationTreeRootGUID: '',
            measurementProgrammeTreeRootGuid: '',
            UITheme: 'primary',

            CcUgdmMetaEnvironment: {},
            CcUgdmQueryParameter: {},
            message: '',
            error: '',
            returnUrl: '',
            pageGUID: '',
            pageName: '',
            pageTitle: '',

            userName: '',
            userId: '',
            roles: [],
            roleIds: [],
            bearerToken: '',

            applicationBaseUrl: '',
            APIBaseUrl: '',
            OdataBaseUrl: '',
            ApplicationDataServiceBaseUrl: '', // To obtain metadata

            serverVersion: '1.0',
            clientVersion: '1.0',

            // authenticationServerAdresse: null //Not used anymore
            document: window.document,
            metadata: [],
        }
    }
}

export interface ParameterElement {
    parameter: string
    callbacks: {
        id: string
        fn: Function
    }[]
}

/*
export const configurationCache = writable(createDefaultConfig(CcUgdmMetaEnvironment));
export const parameterCache = writable(new Array<ParameterElement>());
*/

export interface ParameterServiceInterface {
    constants: object // BryteCube constants
    cache: Array<ParameterElement>
    appParameters: ApplicationParameterInterface // environment parameters

    // the original parameter service
    // configuration[parameter]

    // SET a parameter and publish to all callback already registered on that parameter
    // if compare is true, a clone will be stored, otherwise the object itself is stored
    set: (parameter: string, parameterValue: any, compare?: boolean, madeby?: string) => void
    
    // GET a parameter
    // if copy is true, a clone will be returned, otherwise the object itself
    get: (parameter: string, copy?: boolean) => any
    
    remove: (parameter: string) => void

    // sub setting of the original parameter service for metadata
    // metadata array under configuration['ResourceMetadata']
    // parameter is the guid of the view
    setMetadata: (parameter: string, value: any) => void // parameter is the guid of the view
    getMetadata: (parameter: string) => Promise<EntityType> // parameter is the guid of the view
    tryGetMetadata: (parameter: string) => EntityType // synch fetching from local variable, get it only if already loaded
    
    // redundancy to message service!
    // register callback if parameter changes
    subscribe: (parameter: string, idSubscriber: string, callback: Function /* REact: setStateMethod */) => void
    unsubscribe: (parameter: string, idSubscriber: string) => void
    // not used -> redundant to message service
    unsubscribeComponent: (idSubscriber: string) => void
    
}

export const createParamterService = (appConfiguration?: CcUgdmMetaEnvironmentInterface): ParameterServiceInterface => {

    // constant parameters set from environment
    let configuration = createDefaultConfig(appConfiguration)

    console.log("createParamterService: " + configuration.OdataBaseUrl)
    
    // only for callback functions, parameters are in configuration
    let myCache: ParameterElement[] = []
    
    // console.log("createParamterService called", configuration, myCache);

    let currentRequestMap = new Map<string, Promise<EntityType>>()

    return {
        constants: constants,
        cache: myCache,
        appParameters: configuration,
        // pendingRequestIds: ongoingRequestsIds,
        // pendingRequests: ongoingRequests,

        set: (parameter: string, parameterValue: any, compare?: boolean, madeby?: string) => {

            let changed : boolean = false;

            const cacheElement = myCache.find((c) => c.parameter === parameter); // only for callback, value in configuration array

            if (cacheElement) {

                if (compare && compare === true) {
                    let compareValue
                    if (typeof parameterValue === 'object') {
                        compareValue = JSON.parse(JSON.stringify(parameterValue))
                    } else {
                        compareValue = parameterValue
                    }

                    if (!deepEqual(compareValue, configuration[parameter], {strict: true})) {

                        configuration[parameter] = compareValue
                        changed = true;

                        /////////////////////////////////////////////////////
                        // Execute registered callbacks of subscribers if any
                        /////////////////////////////////////////////////////
                        for (let i = 0; i < cacheElement.callbacks.length; i++) {

                            const callbackObject = cacheElement.callbacks[i]
                            // console.log('callbackObject', callbackObject.id)
                            callbackObject.fn(clone(parameterValue))
                        }
                    }
                    // else{
                    //     console.log('same Object, do not execute callbacks!!!!!', parameter, madeby)
                    // }
                } else {
                    // If there is no compare, then the value in the cache equals
                    //  the passed value AND it's reference
                    configuration[parameter] = parameterValue
                    changed = true;

                    /////////////////////////////////////////////////////
                    // Execute registered callbacks of subscribers if any
                    /////////////////////////////////////////////////////
                    let callbacks = cacheElement.callbacks.map(fn => fn.fn)
                    for (let i = 0; i < callbacks.length; i++) {
                        const callbackObject = callbacks[i]
                        callbackObject(parameterValue)
                    }
                }
            } else {
                if (compare && compare === true) {
                    configuration[parameter] = clone(parameterValue)
                } else {
                    configuration[parameter] = parameterValue
                }
                changed = true;
            }

        },

        get: (parameter: string, copy?: boolean): any => {
            // check if constant parameter
            if (configuration[parameter] !== null && configuration[parameter] !== undefined) {
                if (parameter === 'MAP_COMPONENT') {
                    return configuration[parameter]
                } else {
                    if (copy && copy === true) {
                        if (typeof configuration[parameter] === 'object') {
                            return clone(configuration[parameter])
                        } else {
                            return configuration[parameter]
                        }
                    } else {
                        return configuration[parameter]
                    }
                }
            } else {
                return null
            }
        },

        remove: (parameter: string): void => {
            // If the parameter exists
            if (configuration[parameter]) {
                
                // console.log('remove ', parameter)
                delete configuration[parameter]

                // update the configuration cache store
                // configurationCache.set(configuration);
            }
        },

        // parameter is the guid of the view
        setMetadata: (parameter: string, value: any) => {
            try {
                let group = configuration['ResourceMetadata']
                if (group) {
                    group[parameter] = value
                } else {
                    configuration['ResourceMetadata'] = {}
                    configuration['ResourceMetadata'][parameter] = value
                }

                // update the configuration cache store
                // configurationCache.set(configuration);

            } catch (error) {
                throw error
            }
        },

        // parameter is the guid of the view
        getMetadata: async (parameter: string): Promise<EntityType> => {
            try {
                // Metadata is stored in configuration['ResourceMetadata'], create it if not present
                let group = configuration['ResourceMetadata']
                if (!group) {
                    configuration['ResourceMetadata'] = {}
                    group = configuration['ResourceMetadata']

                    // update the configuration cache store
                    // configurationCache.set(configuration);
                }

                // check if metadata already loaded
                const param = group[parameter] 
                if (param !== undefined && param !== null) {
                    return param
                } else {
                    // check if currently already fetching this metadata
                    let currentRequest = currentRequestMap.get(parameter)
                    if (currentRequest) {

                        await currentRequest;

                        currentRequestMap.delete(parameter);

                        // update the configuration cache store not required as metadata manager sets parameters through set

                        return configuration['ResourceMetadata'][parameter]

                    } else {

                        // fetch metadata from the server
                        currentRequestMap.set(parameter, getViewMetadata(parameter))
                        return currentRequestMap.get(parameter).then(result=>{
                            currentRequestMap.delete(parameter);
                            
                            // update the configuration cache store not required as metadata manager sets parameters through set
                            
                            return configuration['ResourceMetadata'][parameter]
                        })
                    }
                }
            } catch (error) {
                throw error
            }
        },

        // get it if already loaded
        tryGetMetadata: (parameter: string): EntityType => {
            try {
                let group = configuration['ResourceMetadata']
                if (!group) {
                    configuration['ResourceMetadata'] = {}
                    group = configuration['ResourceMetadata']

                    // update the configuration cache store
                    // configurationCache.set(configuration);
                }
                const param = group[parameter]
                if (param !== undefined && param !== null) {
                    return param
                } 
                return null
            } catch (error) {
                throw error
            }
        },

        // getMetadata: async (parameter: string): Promise<EntityType> => {
        //
        //
        //     try {
        //         const group = configuration['ResourceMetadata']
        //         if (group) {
        //             const param = group[parameter]
        //             if (param !== undefined && param !== null) {
        //                 return param
        //             } else {
        //                 let userView = isUserView(parameter)
        //                 if(userView){
        //                     await bc.metadataManager.fetchEntityDetail3(parameter)
        //                 }else{
        //                     await bc.metadataManager.fetchEntityDetail(parameter)
        //                 }
        //
        //                 return group[parameter]
        //             }
        //         } else {
        //             configuration['ResourceMetadata'] = {}
        //             let userView = isUserView(parameter)
        //             console.log('userView',  parameter, userView)
        //             if(userView){
        //                 await bc.metadataManager.fetchEntityDetail3(parameter)
        //             }else{
        //                 await bc.metadataManager.fetchEntityDetail(parameter)
        //             }
        //             return configuration['ResourceMetadata'][parameter]
        //
        //         }
        //     } catch (error) {
        //         throw error
        //     }
        // },

        subscribe: (parameter: string, idSubscriber: string, callback: Function) => {
            if (!myCache.find((c) => c.parameter === parameter && c.callbacks.find((call) => call.id === idSubscriber)!)) {
                // If only the callback has to be added
                const cacheElement = myCache.find((c) => c.parameter === parameter)
                if (cacheElement) {
                    cacheElement.callbacks.push({id: idSubscriber, fn: callback})
                    //Whole ParameterElement has to be added
                } else {
                    myCache.push({parameter, callbacks: [{id: idSubscriber, fn: callback}]})
                }
            } else {
                const cacheElement = myCache.find((c) => c.parameter === parameter && c.callbacks.find((call) => call.id === idSubscriber)!)
                let add = true
                cacheElement.callbacks.forEach((elem) => {
                    if (elem.id === idSubscriber && elem.fn === callback) {
                        add = false
                    }
                })
                if (add) {
                    cacheElement.callbacks.push({id: idSubscriber, fn: callback})
                }
            }

            // update the parameter cache store
            // parameterCache.set(myCache);
        },

        unsubscribe: (parameter: string, idSubscriber: string) => {
            const cacheElement = myCache.find((c) => c.parameter === parameter)
            if (cacheElement) {
                // Remove the callback from the array with the id === idSubscriber
                const callbackObject = cacheElement.callbacks
                let index = 0
                let found = false
                for (let i = 0; i < callbackObject.length; i++) {
                    if (callbackObject[i].id === idSubscriber) {
                        index = i
                        found = true
                        break
                    }
                }
                if (found) {
                    callbackObject.splice(index, 1)

                    // update the parameter cache store
                    // parameterCache.set(myCache);
                }

                // console.log('cacheElement', idSubscriber, cacheElement)

                // If the cacheElement doens not have anymore callback for the given parameter
                // then we can remove the while cacheElement from the cache
                // if (callbackObject.length === 0) {
                //     let indexCacheElement = 0
                //     for (let i = 0; i < myCache.length; i++) {
                //         if (myCache[i].parameter === parameter) {
                //             indexCacheElement = i
                //             break
                //         }
                //     }
                //     myCache.splice(indexCacheElement, 1)
                // }
            }
        },

        unsubscribeComponent: (idSubscriber: string) => {
            for (let i = 0; i < myCache.length; i++) {
                let cacheElement = myCache[i]
                cacheElement.callbacks = cacheElement.callbacks.filter(cb => cb.id !== idSubscriber)
            }
            
            // update the parameter cache store
            // parameterCache.set(myCache);
        },
        
    }
}


export const parameterService: ParameterServiceInterface = createParamterService(CcUgdmMetaEnvironment)

const getViewMetadata = async (parameter: string) =>{

    if(parameterService.appParameters.serverVersion >= "4.0") {
        // 4.0: only view metadata
        return bc.metadataManager.fetchEntityDetail4(parameter)
    } else {
        // pre 4.0: separate 
        let userView = await isUserView(parameter)
        if (userView) {
            return bc.metadataManager.fetchEntityDetail3(parameter)
        } else {
            console.log('isNotUserView', parameter)
            return bc.metadataManager.fetchEntityDetail(parameter)
        }

    }


    
}


const isUserView = async (viewGuid: string) => {
    let guids = await getUserViews()
    if (guids.includes(viewGuid)) {
        return true
    }
    return false
}

const getUserViews = async() =>{
    let guids = parameterService.get('USER_VIEW_GUIDS')
    if (guids === null) {
        let userViewGuids = await bc.metadataManager.getCachedUgdmTypesByDomain(constants.domain.EY_APP_DATA_VIEW)
        guids = userViewGuids.map(m => m.TY_ID)
        parameterService.set('USER_VIEW_GUIDS', guids)
    }
    return guids
}
